import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import flags_controller_pipelines from './new-pipeline/flags-controller'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    flags_controller_pipelines
  },
  strict: process.env.DEV,
})
