<template>
  <b-overlay :show="flagShowOverlay">
    <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
    >
      <component :is="layout">
        <router-view />
      </component>

    </div>

    <template #overlay>
      <div class="text-center d-flex flex-column align-items-center">
        <feather-icon
          icon="ClockIcon"
          size="24"
          class="mb-1"
        />
          {{$t('PleaseWait')}}
      </div>
    </template>
  </b-overlay>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import { BOverlay } from 'bootstrap-vue';
import router from '@/router'
import { mapGetters, mapMutations } from "vuex";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BOverlay

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters('app', [
      'flagShowOverlay'
    ]),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created(){
    let locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
    this.$i18n.locale = locale
    this.getSites()

    router.beforeEach((to, from, next) => {
      if(to.name!= from.name) this.UPDATE_FLAG_SHOW_OVERLAY(true);
      next();
    })
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods:{
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    getSites(){
      let prefix =  localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix
      this.$http.get(`/api/${prefix}/tenant-site`)
          .then(response => localStorage.setItem('sites', JSON.stringify(response.data)))
          .catch(error => {})
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style lang="scss">

  /* .b-overlay-wrap {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  } */

  .b-overlay {
    .position-absolute {
      &:nth-child(2) {
        position: fixed !important;
        top: 50vh !important;
        left: 0;
      }
    }
  }

  * {
    font-family: 'Raleway', sans-serif !important;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
  }

</style>
