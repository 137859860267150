import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path:'/',
      redirect:'/:prefix/order',
      name: 'home'
    },
    {
      path: '/:prefix/resource-loading',
      name: 'resource-loading',
      component: () => import('@/views/pages/dashboard/ResourceLoading.vue'),
      meta: {
        resource: 'pcp',
        breadcrumb: [
          {
            text: 'Resource Loading',
            active: true,
            icon:'dashboard'
          },
        ],
      },
    },
    {
      path: '/:prefix/schedule-analysis',
      name: 'schedule-analysis',
      component: () => import('@/views/pages/dashboard/ScheduleAnalysis.vue'),
      meta: {
        resource: 'pcp',
        breadcrumb: [
          {
            text: 'Schedule Analysis',
            active: true,
            icon:'dashboard'
          },
        ],
      },
    },
    {
      path: '/:prefix/oee',
      name: 'oee',
      component: () => import('@/views/pages/dashboard/Oee.vue'),
      meta: {
        resource: 'pcp',
        breadcrumb: [
          {
            text: 'OEE',
            active: true,
            icon:'dashboard'
          },
        ],
      },
    },
    {
      path: '/:prefix/order',
      name: 'order-list',
      component: () => import('@/views/pages/order/OrderList.vue'),
      meta: {
        resource: 'pcp',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
            icon:'order'
          },
        ],
      },
    },
    {
      path: '/:prefix/order-details/:order_id/:order_no?/:op_no?',
      name: 'order-details',
      component: () => import('@/views/pages/order/OrderDetails.vue'),
      meta: {
        resource: 'pcp',
        breadcrumb: [
          {
            text: 'Orders',
            to: {name: 'order-list'},
            icon:'order'
          },
          {
            text: 'Informations',
            active: true,
          },
        ],
      },
    },
    {
      path: '/:prefix/production-progress/:order_id?/:order_no?/:op_no?',
      name: 'production-progress',
      component: () => import('@/views/pages/production-progress/ProductionProgress.vue'),
      meta: {
        resource: 'pcp',
        breadcrumb: [
          {
            text: 'Production Progress',
            active: true,
            icon:'operation'
          },
        ],
      },
    },
    {
      path: '/:prefix?/login',
      name: 'login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/:prefix?/forgot',
      name: 'forgot',
      component: () => import('@/views/pages/authentication/Forgot.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/:prefix/reset-password/:code',
      name: 'reset-password',
      component: () => import('@/views/pages/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/invalid-link',
      name: 'invalid-link',
      component: () => import('@/views/pages/authentication/InvalidLink.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'basic',
      },
    },
    {
      path: '/:prefix/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'basic',
      },
    },
    {
      path: '/:prefix/authenticate-required',
      name: 'authenticate-required',
      component: () => import('@/views/pages/miscellaneous/AuthenticateRequired.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'basic',
      },
    },
    {
      path: '/:prefix/account-setting',
      name: 'account-setting',
      component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
      meta: {
        resource: 'operator',
        action: 'read',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
            icon:'profile'
          },
        ],
      },
    },
    {
      path: '/:prefix/support',
      name: 'support',
      component: () => import('@/views/pages/support/Support.vue'),
      meta: {
        resource: 'basic',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
            icon:'profile'
          },
        ],
      },
    },
    {
      path: '/:prefix/operator',
      name: 'operator',
      component: () => import('@/views/pages/operator/Operator.vue'),
      meta: {
        resource: 'operator',
        action: 'read',
      },
    },
    {
      path: '/:prefix/operator/resources',
      name: 'operator-resource',
      component: () => import('@/views/pages/operator/Resources.vue'),
      meta: {
        resource: 'operator',
        action: 'read',
      },
    },
    {
      path: '/:prefix/operator/events/resources',
      name: 'operator-events-resource',
      component: () => import('@/views/pages/operator/events/Resources.vue'),
      meta: {
        resource: 'operator',
        action: 'read',
      },
    },
    {
      path: '/:prefix/operator/resources/:resource_id',
      name: 'operator-orders',
      component: () => import('@/views/pages/operator/Orders.vue'),
      meta: {
        resource: 'operator',
        action: 'read',
      },
    },
    {
      path: '/:prefix/operator/orders',
      name: 'operator-orders-all',
      component: () => import('@/views/pages/operator/orders/Orders.vue'),
      meta: {
        resource: 'operator',
        action: 'read',
      },
    },
    {
      path: '/:prefix/operator/production-progress/:resource_id/:order_id/:return_orders/:multiple?',
      name: 'operator-production-progress',
      component: () => import('@/views/pages/operator/ProductionProgress.vue'),
      meta: {
        resource: 'operator',
        action: 'read',
        breadcrumb: [
          {
            text: 'Orders',
            active: false,
            to: {name:'operator'},
            icon:'operator'
          },
          {
            text: 'Production Progress',
            active: true,
            icon:'operator'
          },
        ],
      },
    },
    {
      path: '/:prefix/operator/production-progress-multiple/:resource_id/:multiple/:return_orders?/preactor_orders=:preactor_orders?',
      name: 'operator-production-progress-multiple',
      component: () => import('@/views/pages/operator/ProductionProgress.vue'),
      meta: {
        resource: 'operator',
        action: 'read',
        breadcrumb: [
          {
            text: 'Orders',
            active: false,
            to: {name:'operator'},
            icon:'operator'
          },
          {
            text: 'Production Progress',
            active: true,
            icon:'operator'
          },
        ],
      },
    },
    {
      path: '/:prefix/system-setting/resource-status',
      name: 'resource-status',
      component: () => import('@/views/pages/system-setting/ResourceStatus.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Classification',
            active: false,
            icon:'settings'
          },
          {
            text: 'Resource Status',
            active: true,
          },
        ],
      },
    },
    {
      path: '/:prefix/system-setting/refuse-status',
      name: 'refuse-status',
      component: () => import('@/views/pages/system-setting/RefuseStatus.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Classification',
            active: false,
            icon:'settings'
          },
          {
            text: 'Rejection Reason',
            active: true,
          },
        ],
      },
    },
    {
      path: '/:prefix/system-setting/sites',
      name: 'sites',
      component: () => import('@/views/pages/system-setting/Sites.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Sites',
            active: true,
            icon:'settings'
          },
        ],
      },
    },
    {
      path: '/:prefix/system-setting/resources',
      name: 'resources',
      component: () => import('@/views/pages/system-setting/Resources.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Primary Resources',
            active: true,
            icon:'settings'
          },
        ],
      },
    },
    {
      path: '/:prefix/system-setting/workcenters',
      name: 'workcenters',
      component: () => import('@/views/pages/system-setting/Workcenters.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
        breadcrumb: [
          {
            text: 'Workcenters',
            active: true,
            icon:'settings'
          },
        ],
      },
    },
    {
      path: '/:prefix/system-setting/params',
      name: 'params',
      component: () => import('@/views/pages/system-setting/Params.vue'),
      meta: {
        resource: 'pcp',
        action: 'read',
      },
    },
    {
      path: '/:prefix/integration/data-source',
      name: 'data-source',
      component: () => import('@/views/pages/integration/data-source/DataSource.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Data Source',
            active: true,
            icon:'integration'
          },
        ],
      },
    },
    {
      path: '/:prefix/integration/pipelines',
      name: 'pipelines',
      component: () => import('@/views/pages/integration/pipelines/Pipelines.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Pipelines',
            active: true,
            icon:'integration'
          },
        ],
      },
    },
    {
      path: '/:prefix/integration/new-pipeline/:action?/:id?',
      name: 'new-pipeline',
      component: () => import('@/views/pages/integration/pipelines/NewPipeline.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Pipelines',
            active: false,
            to: {name:'pipelines'},
            icon:'integration'
          },
          {
            text: 'IntegrationPage.NewPipeline',
            active: true,
          },
        ],
      },
    },
    {
      path: '/:prefix/integration/object/:connection_id/:type',
      name: 'object',
      component: () => import('@/views/pages/integration/objects/Object.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Data Source',
            to: {name: 'data-source'},
            icon:'integration'
          },
          {
            text: 'Object',
            active: true,
          },
        ],
      },
    },
    {
      path: '/:prefix/integration/log-center',
      name: 'log-center',
      component: () => import('@/views/pages/integration/log-center/LogCenter.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Log Center',
            active: true,
            icon:'integration'
          },
        ],
      },
    },
    {
      path: '/:prefix/integration/notification/download-client',
      name: 'download-client',
      component: () => import('@/views/DownloadClient.vue'),
      meta: {
        resource: 'integration',
        action: 'read',
        breadcrumb: [
          {
            text: 'Download',
            active: true,
            icon:'integration'
          },
        ],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {

    let pcpAccess = null
    if (JSON.parse(localStorage.getItem('userData')))
        pcpAccess = JSON.parse(localStorage.getItem('userData')).ability.find( element => element.action == 'manage') || JSON.parse(localStorage.getItem('userData')).ability.find( element => element.subject == 'pcp');

    const isLoggedIn = isUserLoggedIn()

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        return next({ name: 'home' , params: {prefix: to.params.prefix}})
    }

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'login', params: {prefix: to.params.prefix} })

        // Redirect to operator mode
        if (!pcpAccess) return next({ name: 'operator' , params: {prefix: localStorage.getItem('prefix')}})

        // If logged in => not authorized
        return next({ name: 'home' , params: {prefix: localStorage.getItem('prefix')}})
    }

    return next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
