export default {
  namespaced: true,
  state: {
    flagShowOverlay: false,

    //flag actions
    flagIntegrationAction: false,
    flagShippingAction: false,
    flagAzureDataFactoryAction: false,
    flagRunProcedureAction: false,
    flagRunPowerShellAction: false,
    flagRunPipelineAction: false,

    //Iboxs and tabs
    flagPipelineSessionTab: true,
    flagActivitySessionTab: false,
    flagSchedulesSessionTab: true,
    flagAlertsSessionTab: false,
    flagNewSchedulesIbox: false,
    flagActivitiesIbox: false,
    flagNewActivityIbox: true,
    flagIntegrationIbox: false,
    flagMappingFieldsIbox: false,
    flagReturnFieldsIbox: false,
    flagIdentificationKeyIbox: false,
    flagRoutineBehaviorIbox: false,
    flagProceduresIbox: false,
    flagLotsIbox: false,
    flagShippingIbox: false,
    flagAfterMapIboxs: false,

    //validations order
    flagValidateActivities: false,
    flagValidateSchedules: false,

    //validations
    flagPipelineSessionValidation: null,
    flagActivitySessionValidation: null,
    flagAlertsSessionValidation: null,
    flagSchedulesSessionValidation: null,
    flagActivitiesValidation: null,
    flagNewActivityValidation: null,
    flagIntegrationValidation: null,
    flagMappingFieldsValidation: null,
    flagReturnFieldsValidation: null,
    flagIdentificationKeyValidation: null,
    flagRoutineBehaviorValidation: null,
    flagProceduresValidation: null,
    flagLotsValidation: null,
    flagShippingValidation: null,
    flagAlertsValidation: null,
    flagAzureDataFactoryValidation: null,
    flagRunProcedureValidation: null,
    flagRunPowerShellValidation: null,
    flagRunPipelineValidation: null,

    //buttons
    flagFloatSaveButton: false,
    flagActivityButton: false,
    flagSchedulesButton: false,
    flagEditActivityButton: false,
    flagEditSchedulesButton: false,
    flagWaitReturnButton: false,

    //flagAddAndEdit
    flagEditActivity: false,
    flagEditActivityFixed: false,
    flagEditSchedules: false,

    //flag clean
    flagCleanFields: false,
    flagUpdateList: false,
    flagCleanNewActivity: false,

    //has content in list
    hasContentExecutionTypeList: false,
    hasContentEventList:false,

    //flag get route
    flagGetContext: false,
    flagGetPipeline: false,
    flagDisableEventAlreadyRegistred: false,

    //validations show alerts
    selectedPerformedVia: null,
    selectedEvent: null,
    selectedAction: null,
    selectedActivityGroup: null,
    setOldValueSelectedActivityGroup: true,
    canLinkAlert: true,
    confirmChange: false,
    linkedActivityListEmpty: true,
    flagConfirmChangeOrderLinkedActivityList: null,
    flagLinkedActivityListDeleted: null,
  },
  getters: {
    flagShowOverlay: state => state.flagShowOverlay,

    //order validation
    flagValidateActivities: state => state.flagValidateActivities,

    //flag actions
    flagIntegrationAction: state => state.flagIntegrationAction,
    flagShippingAction: state => state.flagShippingAction,
    flagAzureDataFactoryAction: state => state.flagAzureDataFactoryAction,
    flagRunProcedureAction: state => state.flagRunProcedureAction,
    flagRunPowerShellAction: state => state.flagRunPowerShellAction,
    flagRunPipelineAction: state => state.flagRunPipelineAction,

    //Ibox and tabs
    flagPipelineSessionTab: state => state.flagPipelineSessionTab,
    flagActivitySessionTab: state => state.flagActivitySessionTab,
    flagSchedulesSessionTab: state => state.flagSchedulesSessionTab,
    flagAlertsSessionTab: state => state.flagAlertsSessionTab,
    flagNewSchedulesIbox: state => state.flagNewSchedulesIbox,
    flagActivitiesIbox: state => state.flagActivitiesIbox,
    flagNewActivityIbox: state => state.flagNewActivityIbox,
    flagIntegrationIbox: state => state.flagIntegrationIbox,
    flagMappingFieldsIbox: state => state.flagMappingFieldsIbox,
    flagReturnFieldsIbox: state => state.flagReturnFieldsIbox,
    flagIdentificationKeyIbox: state => state.flagIdentificationKeyIbox,
    flagRoutineBehaviorIbox: state => state.flagRoutineBehaviorIbox,
    flagProceduresIbox: state => state.flagProceduresIbox,
    flagLotsIbox: state => state.flagLotsIbox,
    flagShippingIbox: state => state.flagShippingIbox,
    flagAfterMapIboxs: state => state.flagAfterMapIboxs,

    //validations
    flagPipelineSessionValidation: state => state.flagPipelineSessionValidation,
    flagActivitySessionValidation: state => state.flagActivitySessionValidation,
    flagAlertsSessionValidation: state => state.flagAlertsSessionValidation,
    flagSchedulesSessionValidation: state => state.flagSchedulesSessionValidation,
    flagActivitiesValidation: state => state.flagActivitiesValidation,
    flagNewActivityValidation: state => state.flagNewActivityValidation,
    flagIntegrationValidation: state => state.flagIntegrationValidation,
    flagAlertsValidation: state => state.flagAlertsValidation,
    flagMappingFieldsValidation: state => state.flagMappingFieldsValidation,
    flagReturnFieldsValidation: state => state.flagReturnFieldsValidation,
    flagIdentificationKeyValidation: state => state.flagIdentificationKeyValidation,
    flagRoutineBehaviorValidation: state => state.flagRoutineBehaviorValidation,
    flagProceduresValidation: state => state.flagProceduresValidation,
    flagLotsValidation: state => state.flagLotsValidation,
    flagShippingValidation: state => state.flagShippingValidation,
    flagAzureDataFactoryValidation: state => state.flagAzureDataFactoryValidation,
    flagRunProcedureValidation: state => state.flagRunProcedureValidation,
    flagRunPowerShellValidation: state => state.flagRunPowerShellValidation,
    flagRunPipelineValidation: state => state.flagRunPipelineValidation,

    //buttons
    flagFloatSaveButton: state => state.flagFloatSaveButton,
    flagActivityButton: state => state.flagActivityButton,
    flagSchedulesButton: state => state.flagSchedulesButton,
    flagEditActivityButton: state => state.flagEditActivityButton,
    flagEditSchedulesButton: state => state.flagEditSchedulesButton,
    flagWaitReturnButton: state => state.flagWaitReturnButton,

    //flag add and edit
    flagEditActivity: state => state.flagEditActivity,
    flagEditActivityFixed: state => state.flagEditActivityFixed,
    flagEditSchedules: state => state.flagEditSchedules,

    //flagCleanFields
    flagCleanFields: state => state.flagCleanFields,
    flagUpdateList: state => state.flagUpdateList,
    flagCleanNewActivity: state => state.flagCleanNewActivity,

    //has content in list
    hasContentExecutionTypeList: state => state.hasContentExecutionTypeList,
    hasContentEventList: state => state.hasContentEventList,

    //flag get route
    flagGetContext: state => state.flagGetContext,
    flagGetPipeline: state => state.flagGetPipeline,
    flagDisableEventAlreadyRegistred: state => state.flagDisableEventAlreadyRegistred,

    //validations show alerts
    selectedPerformedVia: state => state.selectedPerformedVia,
    selectedEvent: state => state.selectedEvent,
    selectedAction: state => state.selectedAction,
    selectedActivityGroup: state => state.selectedActivityGroup,
    setOldValueSelectedActivityGroup: state => state.setOldValueSelectedActivityGroup,
    canLinkAlert: state => state.canLinkAlert,
    confirmChange: state => state.confirmChange,
    linkedActivityListEmpty: state => state.linkedActivityListEmpty,
    flagConfirmChangeOrderLinkedActivityList: state => state.flagConfirmChangeOrderLinkedActivityList,
    flagLinkedActivityListDeleted: state => state.flagLinkedActivityListDeleted,
  },
  mutations: {
    UPDATE_FLAG_SHOW_OVERLAY(state, payload) {
      state.flagShowOverlay = payload;
    },

    //flag actions
    UPDATE_FLAG_INTEGRATION_ACTION(state, payload) {
      state.flagIntegrationAction = payload;
    },
    UPDATE_FLAG_SHIPPING_ACTION(state, payload) {
      state.flagShippingAction = payload;
    },
    UPDATE_FLAG_AZURE_DATA_FACTORY_ACTION(state, payload) {
      state.flagAzureDataFactoryAction = payload;
    },
    UPDATE_FLAG_RUN_PROCEDURE_ACTION(state, payload) {
      state.flagRunProcedureAction = payload;
    },
    UPDATE_FLAG_RUN_POWERSHELL_ACTION(state, payload) {
      state.flagRunPowerShellAction = payload;
    },
    UPDATE_FLAG_RUN_PIPELINE_ACTION(state, payload){
        state.flagRunPipelineAction = payload;
    },

    //IBOXS E TABS
    UPDATE_FLAG_PIPELINE_SESSION(state, payload) {
      state.flagPipelineSessionTab = payload;
    },
    UPDATE_FLAG_ACTIVITY_SESSION(state, payload) {
      state.flagActivitySessionTab = payload;
    },
    UPDATE_FLAG_SCHEDULES_SESSION(state, payload) {
      state.flagSchedulesSessionTab = payload;
    },
    UPDATE_FLAG_ALERTS_SESSION(state, payload){
      state.flagAlertsSessionTab = payload;
    },
    UPDATE_FLAG_ACTIVITIES_IBOX(state, payload) {
      state.flagActivitiesIbox = payload;
    },
    UPDATE_FLAG_NEW_SCHEDULES_IBOX(state, payload) {
      state.flagNewSchedulesIbox = payload;
    },
    UPDATE_FLAG_NEW_ACTIVITY_IBOX(state, payload) {
      state.flagNewActivityIbox = payload;
    },
    UPDATE_FLAG_INTEGRATION_IBOX(state, payload) {
      state.flagIntegrationIbox = payload;
    },
    UPDATE_FLAG_MAPPING_FIELDS_IBOX(state, payload) {
      state.flagMappingFieldsIbox = payload;
    },
    UPDATE_FLAG_RETURN_FIELDS_IBOX(state, payload) {
      state.flagReturnFieldsIbox = payload;
    },
    UPDATE_FLAG_IDENTIFICATION_KEY_IBOX(state, payload) {
      state.flagIdentificationKeyIbox = payload;
    },
    UPDATE_FLAG_ROUTINE_BEHAVIOR_IBOX(state, payload) {
      state.flagRoutineBehaviorIbox = payload;
    },
    UPDATE_FLAG_PROCEDURES_IBOX(state, payload) {
      state.flagProceduresIbox = payload;
    },
    UPDATE_FLAG_LOTS_IBOX(state, payload) {
      state.flagLotsIbox = payload;
    },
    UPDATE_FLAG_SHIPPING_IBOX(state, payload) {
      state.flagShippingIbox = payload;
    },
    UPDATE_AFTER_MAP_IBOXS(state, payload) {
      state.flagAfterMapIboxs = payload;
    },

    //ORDER VALIDATIONS
    UPDATE_FLAG_VALIDATE_ACTIVITIES(state, payload) {
      state.flagValidateActivities = payload;
    },
    UPDATE_FLAG_VALIDATE_SCHEDULES(state, payload) {
      state.flagValidateSchedules = payload;
    },

    //VALIDATIONS
    UPDATE_FLAG_PIPELINE_SESSION_VALIDATION(state, payload) {
      state.flagPipelineSessionValidation = payload;
    },
    UPDATE_FLAG_ACTIVITY_SESSION_VALIDATION(state, payload) {
      state.flagActivitySessionValidation = payload;
    },
    UPDATE_FLAG_ALERTS_SESSION_VALIDATION(state, payload) {
      state.flagAlertsSessionValidation = payload;
    },
    UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION(state, payload) {
      state.flagSchedulesSessionValidation = payload;
    },
    UPDATE_FLAG_ACTIVITIES_VALIDATION(state, payload) {
      state.flagActivitiesValidation = payload;
    },
    UPDATE_FLAG_NEW_ACTIVITY_VALIDATION(state, payload) {
      state.flagNewActivityValidation = payload;
    },
    UPDATE_FLAG_INTEGRATION_VALIDATION(state, payload) {
      state.flagIntegrationValidation = payload;
    },
    UPDATE_FLAG_ALERTS_VALIDATION(state, payload) {
      state.flagAlertsValidation = payload;
    },
    UPDATE_FLAG_MAPPING_FIELDS_VALIDATION(state, payload) {
      state.flagMappingFieldsValidation = payload;
    },
    UPDATE_FLAG_RETURN_FIELDS_VALIDATION(state, payload) {
      state.flagReturnFieldsValidation = payload;
    },
    UPDATE_FLAG_IDENTIFICATION_KEYS_VALIDATION(state, payload) {
      state.flagIdentificationKeyValidation = payload;
    },
    UPDATE_FLAG_ROUTINE_BEHAVIOR_VALIDATION(state, payload) {
      state.flagRoutineBehaviorValidation = payload;
    },
    UPDATE_FLAG_PROCEDURES_VALIDATION(state, payload) {
      state.flagProceduresValidation = payload;
    },
    UPDATE_FLAG_LOTS_VALIDATION(state, payload) {
      state.flagLotsValidation = payload;
    },
    UPDATE_FLAG_SHIPPING_VALIDATION(state, payload) {
      state.flagShippingValidation = payload;
    },
    UPDATE_FLAG_AZURE_DATA_FACTORY_VALIDATION(state, payload) {
      state.flagAzureDataFactoryValidation = payload;
    },
    UPDATE_FLAG_RUN_PROCEDURE_VALIDATION(state, payload) {
      state.flagRunProcedureValidation = payload;
    },
    UPDATE_FLAG_RUN_POWERSHELL_VALIDATION(state, payload) {
      state.flagRunPowerShellValidation = payload;
    },
    UPDATE_FLAG_RUN_PIPELINE_VALIDATION(state, payload){
        state.flagRunPipelineValidation = payload
    },

    //BUTTONS
    UPDATE_FLAG_FLOAT_SAVE_BUTTON(state, payload) {
        state.flagFloatSaveButton = payload;
    },
    UPDATE_FLAG_ACTIVITY_BUTTON(state, payload) {
        state.flagActivityButton = payload;
    },
    UPDATE_FLAG_SCHEDULES_BUTTON(state, payload) {
        state.flagSchedulesButton = payload;
    },
    UPDATE_FLAG_EDIT_ACTIVITY_BUTTON(state, payload) {
        state.flagEditActivityButton = payload;
    },
    UPDATE_FLAG_EDIT_SCHEDULE_BUTTON(state, payload) {
        state.flagEditSchedulesButton = payload;
    },
    UPDATE_FLAG_WAIT_RETURN_BUTTON(state, payload) {
      state.flagWaitReturnButton = payload;
    },

    //flag add and edit
    UPDATE_FLAG_EDIT_ACTIVITY(state, payload) {
      state.flagEditActivity = payload;
    },
    UPDATE_FLAG_EDIT_ACTIVITY_FIXED(state, payload) {
      state.flagEditActivityFixed = payload;
    },
    UPDATE_FLAG_EDIT_SCHEDULES(state, payload) {
      state.flagEditSchedules = payload;
    },

    //flag clean fields
    UPDATE_FLAG_CLEAN_FIELDS(state, payload) {
      state.flagCleanFields = payload;
    },
    UPDATE_FLAG_CLEAN_NEW_ACTIVITY(state, payload) {
      state.flagCleanNewActivity = payload;
    },

    //has content in list
    UPDATE_HAS_CONTENT_EVENT_LIST(state, payload) {
      state.hasContentEventList = payload;
    },
    UPDATE_HAS_CONTENT_EXECUTION_TYPE_LIST(state, payload) {
      state.hasContentExecutionTypeList = payload;
    },

    // flag get route
    UPDATE_FLAG_GET_CONTEXT(state, payload) {
      state.flagGetContext = payload;
    } ,
    UPDATE_FLAG_GET_PIPELINE(state, payload) {
      state.flagGetPipeline = payload;
    },
    UPDATE_FLAG_DISABLE_EVENT_ALREADY_REGISTRED(state, payload) {
      state.flagDisableEventAlreadyRegistred = payload;
    },

    //validations show alerts
    UPDATE_SELECTED_PERFORMED_VIA(state, payload) {
      state.selectedPerformedVia = payload;
    },
    UPDATE_SELECTED_EVENT(state, payload) {
      state.selectedEvent = payload;
    },
    UPDATE_SELECTED_ACTION(state, payload) {
      state.selectedAction = payload;
    },
    UPDATE_SELECTED_ACTIVITY_GROUP(state, payload) {
      state.selectedActivityGroup = payload;
    },
    UPDATE_OLD_VALUE_SELECTED_ACTIVITY_GROUP(state, payload) {
      state.setOldValueSelectedActivityGroup = payload;
    },
    UPDATE_CAN_LINK_ALERT(state, payload) {
      state.canLinkAlert = payload;
    },
    UPDATE_CONFIRM_CHANGE(state, payload){
      state.confirmChange = payload;
    },
    UPDATE_LINKED_ACTIVITY_LIST_EMPTY(state, payload) {
      state.linkedActivityListEmpty = payload;
    },
    UPDATE_FLAG_CONFIRM_CHANGE_ORDER_LINKED_ACTIVITY_LIST(state, payload) {
      state.flagConfirmChangeOrderLinkedActivityList = payload;
    },
    UPDATE_FLAG_LINKED_ACTIVITY_LIST_DELETED(state, payload) {
      state.flagLinkedActivityListDeleted = payload;
    },
  },
  actions: {
    resetButtonsOfActivity(context) {
      context.commit('UPDATE_FLAG_ADD_ACTIVITY_BUTTON', false);
      context.commit('UPDATE_FLAG_EDIT_ACTIVITY_BUTTON', false);
    },
    resetActionsFlag(context) {
      context.commit('UPDATE_FLAG_INTEGRATION_ACTION', false);
      context.commit('UPDATE_FLAG_SHIPPING_ACTION', false);
      context.commit('UPDATE_FLAG_AZURE_DATA_FACTORY_ACTION', false);
      context.commit('UPDATE_FLAG_RUN_PROCEDURE_ACTION', false);
      context.commit('UPDATE_FLAG_RUN_POWERSHELL_ACTION', false);
      context.commit('UPDATE_FLAG_RUN_PIPELINE_ACTION', false);
    },
    resetIntegrationsIbox(context) {
      context.commit('UPDATE_FLAG_ACTIVITIES_IBOX', false);
      context.commit('UPDATE_FLAG_NEW_ACTIVITY_IBOX', false);
      context.commit('UPDATE_FLAG_INTEGRATION_IBOX', false);
      context.commit('UPDATE_FLAG_MAPPING_FIELDS_IBOX', false);
      context.commit('UPDATE_AFTER_MAP_IBOXS', false);
      context.commit('UPDATE_FLAG_SHIPPING_IBOX', false);
      context.commit('UPDATE_FLAG_CLEAN_FIELDS', false);
    },
    resetAfterMapIBox(context) {
      context.commit('UPDATE_FLAG_RETURN_FIELDS_IBOX', false);
      context.commit('UPDATE_FLAG_IDENTIFICATION_KEY_IBOX', false);
      context.commit('UPDATE_FLAG_ROUTINE_BEHAVIOR_IBOX', false);
      context.commit('UPDATE_FLAG_PROCEDURES_IBOX', false);
      context.commit('UPDATE_FLAG_LOTS_IBOX', false);
    },
    resetIntegrationsValidation(context) {
      context.commit('UPDATE_FLAG_NEW_ACTIVITY_VALIDATION', null);
      context.commit('UPDATE_FLAG_INTEGRATION_VALIDATION', null);
      context.commit('UPDATE_FLAG_MAPPING_FIELDS_VALIDATION', null);
      context.commit('UPDATE_FLAG_RETURN_FIELDS_VALIDATION', null);
      context.commit('UPDATE_FLAG_IDENTIFICATION_KEYS_VALIDATION', null);
      context.commit('UPDATE_FLAG_ROUTINE_BEHAVIOR_VALIDATION', null);
      context.commit('UPDATE_FLAG_PROCEDURES_VALIDATION', null);
      context.commit('UPDATE_FLAG_LOTS_VALIDATION', null);
      context.commit('UPDATE_FLAG_SHIPPING_VALIDATION', null);
      context.commit('UPDATE_FLAG_ALERTS_VALIDATION', null);
      context.commit('UPDATE_FLAG_AZURE_DATA_FACTORY_VALIDATION', null);
      context.commit('UPDATE_FLAG_RUN_PROCEDURE_VALIDATION', null);
      context.commit('UPDATE_FLAG_RUN_POWERSHELL_VALIDATION', null);
      context.commit('UPDATE_FLAG_RUN_PIPELINE_VALIDATION', null);
    },
    resetTabValidations(context) {
      context.commit('UPDATE_FLAG_PIPELINE_SESSION_VALIDATION', null);
      context.commit('UPDATE_FLAG_ACTIVITY_SESSION_VALIDATION', null);
      context.commit('UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION', null);
      context.commit('UPDATE_FLAG_ALERTS_SESSION_VALIDATION', null);
    },
    resetFlags(context) {
        context.commit('UPDATE_FLAG_SHOW_OVERLAY', false);
        context.commit('UPDATE_FLAG_INTEGRATION_ACTION', false);
        context.commit('UPDATE_FLAG_SHIPPING_ACTION', false);
        context.commit('UPDATE_FLAG_AZURE_DATA_FACTORY_ACTION', false);
        context.commit('UPDATE_FLAG_RUN_PROCEDURE_ACTION', false);
        context.commit('UPDATE_FLAG_RUN_POWERSHELL_ACTION', false);
        context.commit('UPDATE_FLAG_RUN_PIPELINE_ACTION', false);
        context.commit('UPDATE_FLAG_PIPELINE_SESSION', false);
        context.commit('UPDATE_FLAG_ACTIVITY_SESSION', false);
        context.commit('UPDATE_FLAG_SCHEDULES_SESSION', true);
        context.commit('UPDATE_FLAG_ACTIVITIES_IBOX', false);
        context.commit('UPDATE_FLAG_NEW_SCHEDULES_IBOX', false);
        context.commit('UPDATE_FLAG_NEW_ACTIVITY_IBOX', true);
        context.commit('UPDATE_FLAG_INTEGRATION_IBOX', false);
        context.commit('UPDATE_FLAG_MAPPING_FIELDS_IBOX', false);
        context.commit('UPDATE_FLAG_RETURN_FIELDS_IBOX', false);
        context.commit('UPDATE_FLAG_IDENTIFICATION_KEY_IBOX', false);
        context.commit('UPDATE_FLAG_ROUTINE_BEHAVIOR_IBOX', false);
        context.commit('UPDATE_FLAG_PROCEDURES_IBOX', false);
        context.commit('UPDATE_FLAG_LOTS_IBOX', false);
        context.commit('UPDATE_FLAG_SHIPPING_IBOX', false);
        context.commit('UPDATE_AFTER_MAP_IBOXS', false);
        context.commit('UPDATE_FLAG_VALIDATE_ACTIVITIES', );
        context.commit('UPDATE_FLAG_VALIDATE_SCHEDULES', );
        context.commit('UPDATE_FLAG_PIPELINE_SESSION_VALIDATION', null);
        context.commit('UPDATE_FLAG_ACTIVITY_SESSION_VALIDATION', null);
        context.commit('UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION', null);
        context.commit('UPDATE_FLAG_ACTIVITIES_VALIDATION', null);
        context.commit('UPDATE_FLAG_NEW_ACTIVITY_VALIDATION', null);
        context.commit('UPDATE_FLAG_INTEGRATION_VALIDATION', null);
        context.commit('UPDATE_FLAG_MAPPING_FIELDS_VALIDATION', null);
        context.commit('UPDATE_FLAG_RETURN_FIELDS_VALIDATION', null);
        context.commit('UPDATE_FLAG_IDENTIFICATION_KEYS_VALIDATION', null);
        context.commit('UPDATE_FLAG_ROUTINE_BEHAVIOR_VALIDATION', null);
        context.commit('UPDATE_FLAG_PROCEDURES_VALIDATION', null);
        context.commit('UPDATE_FLAG_LOTS_VALIDATION', null);
        context.commit('UPDATE_FLAG_SHIPPING_VALIDATION', null);
        context.commit('UPDATE_FLAG_AZURE_DATA_FACTORY_VALIDATION', null);
        context.commit('UPDATE_FLAG_RUN_PROCEDURE_VALIDATION', null);
        context.commit('UPDATE_FLAG_RUN_POWERSHELL_VALIDATION', null);
        context.commit('UPDATE_FLAG_RUN_PIPELINE_VALIDATION', null);
        context.commit('UPDATE_FLAG_FLOAT_SAVE_BUTTON', null);
        context.commit('UPDATE_FLAG_ACTIVITY_BUTTON', false);
        context.commit('UPDATE_FLAG_SCHEDULES_BUTTON', false);
        context.commit('UPDATE_FLAG_EDIT_ACTIVITY_BUTTON', false);
        context.commit('UPDATE_FLAG_EDIT_SCHEDULE_BUTTON', false);
        context.commit('UPDATE_FLAG_EDIT_ACTIVITY', false);
        context.commit('UPDATE_FLAG_EDIT_ACTIVITY_FIXED', false);
        context.commit('UPDATE_FLAG_EDIT_SCHEDULES', false);
        context.commit('UPDATE_FLAG_CLEAN_FIELDS', false);
        context.commit('UPDATE_HAS_CONTENT_EXECUTION_TYPE_LIST', false);
        context.commit('UPDATE_HAS_CONTENT_EVENT_LIST', false);
        context.commit('UPDATE_FLAG_GET_CONTEXT', false);
        context.commit('UPDATE_FLAG_GET_PIPELINE', false);
        context.commit('UPDATE_FLAG_DISABLE_EVENT_ALREADY_REGISTRED', false);
        context.commit('UPDATE_SELECTED_PERFORMED_VIA', null);
        context.commit('UPDATE_SELECTED_EVENT', null);
        context.commit('UPDATE_SELECTED_ACTION', null);
        context.commit('UPDATE_SELECTED_ACTIVITY_GROUP', null);
        context.commit('UPDATE_OLD_VALUE_SELECTED_ACTIVITY_GROUP', true);
        context.commit('UPDATE_CAN_LINK_ALERT', true);
        context.commit('UPDATE_CONFIRM_CHANGE', false);
        context.commit('UPDATE_LINKED_ACTIVITY_LIST_EMPTY', true);
        context.commit('UPDATE_FLAG_CONFIRM_CHANGE_ORDER_LINKED_ACTIVITY_LIST', null);
        context.commit('UPDATE_FLAG_LINKED_ACTIVITY_LIST_DELETED', null);
        context.commit('UPDATE_FLAG_ALERTS_SESSION', false);
        context.commit('UPDATE_FLAG_CLEAN_NEW_ACTIVITY', false);
    }
  },
}
